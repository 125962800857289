// the ~ allows you to reference things in node_modules

$font-weight-bold: 600; 

@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~bootstrap-select/dist/css/bootstrap-select';

@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3';

@import '~@fullcalendar/core/main';
@import '~@fullcalendar/daygrid/main';

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,500;1,600;1,800&display=swap');

.font-weight-bold{
	font-weight: 700!important;
}

h1, .h1 {
	font-size: 3.5rem;
}

h4, .h4 {
	font-size: 2.4rem;
}

@font-face {
  font-family: 'D-DIN';
  src: url(/fonts/d-din/D-DIN.ttf);
}
@font-face {
  font-family: 'D-DIN-BOLD';
  src: url(/fonts/d-din/D-DINExp-Bold.ttf);
}


@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/bebas-neue/BebasNeue-Bold.ttf');
}

@font-face {
  font-family: 'Bebas Neue Regular';
  src: url('/fonts/bebas-neue/BebasNeue-Regular.ttf');
}

.font-d-din{
	  font-family: 'D-DIN';
}

.font-bebas{
  font-family: 'Bebas Neue';
}

.font-bebas-regular{
	font-family: 'Bebas Neue Regular';
}
body{
	font-family: 'D-DIN';
}

h1, h2, h3, h4, .light, .h1, .h2, .h3, .h4, .h5{
	font-family: 'Bebas Neue';
}

strong, .font-bold{
	font-weight: 600 !important;
}

.pt-19-px{
	padding-top: 19px;
}

$gris_clair : #e5e5e5;
$gris_fonce : #565656;
$bleu: #243b55;
$rouge: #e10a0a;

@media (min-width:1200px){
	.container{
		max-width:1240px!important
	}
}
 .footer{
	background-color: $gris_clair;
}


nav{
	li.active{
		background-color: #marron_clair;
	}
	li{
		padding: 4px;
	}

	li a{
		color: #000;
		padding: 0 !important;
	    padding-left: 0.2rem  !important;
	   /* padding-right: 0.5rem  !important;*/
	    font-size: 13px;
	    text-transform: uppercase;

	    .logo{
	    	height: 34px;
	    	width: auto;
	    }

	    .don{
	    	max-width: 47px;
	    }

	}

	.navbar-nav{
		@extend .m-auto;
		li{
			padding: 8px;
		}

		.nav-link{
			font-size: 18px;
		}
		.nav-link.active{
			border-bottom: 1px solid;
		}
	}
	.navbar-nav:nth-of-type(2) li:not(:last-child) a{
	    border-right: 1px solid #fff;
	    padding-right: 13px !important;
	}

	.navbar-brand{
		img{
			max-height: 120px;
    		width: auto;
		}
	}

	.btn-red{
		text-transform: none;
	}
} 

.text-bleu{
	color: $bleu;
}

.fond_gris{
	background-color: $gris_clair;
}

.bottom{
	bottom: 25px;
    position: absolute;
}

.font-grey{
	color: $gris_fonce;
}

.sous-ligne-bleu{
	border-bottom: 2px solid $bleu;
	width: 154px;
}

.form-control, input, textarea{
	border-color: $bleu;
	border-radius: 0px;
}


:-moz-placeholder, ::-moz-placeholder{
   color:    $bleu;
   opacity:  1;
}
::-webkit-input-placeholder , :-ms-input-placeholder, ::-ms-input-placeholder, ::placeholder { /* Most modern browsers support this now. */
   color:    $bleu;
}

.border-bleu{
	border: 1px solid $bleu;
}

.btn-primary{
	background-color: $bleu;
	border-color: $bleu;
	border-radius: 0px;
}
a{
	color: $gris_fonce;
}

.bg-light-grey{
	background-color: lightgrey;
}

.btn-outline-primary{
	color: $bleu;
	border-color: $bleu;
}

.page-item.active .page-link{
	background-color: $bleu;
	border-color: $bleu;
}

.dataTables_wrapper{
	@extend .small;
}

.btn-outline-danger{
	color: #dc3545!important;
}

.btn-outline-danger:hover{
	color: #fff !important;
}

.cursor-pointer{
	cursor: pointer;
}

.w-100-auto{
	max-width:100%; height:auto;
	margin: auto;
}

.socials{
	@extend .list-group, .list-group-horizontal, .list-unstyled;
	li{
		border: none !important;
		padding: 0 !important;
		background-color: transparent;		
	}
}
@media(max-width: 400px){
	.border-bottom{
		border-bottom: none !important;
	}
	h2{
		span{
			width: 100%;		
		}
	}
}

#slider-home{
		
	hr{
		border-top: 2px solid #fff;
		margin-bottom: 0;
	}
	.title{
		font-size: 40px;
	}
	.subtitle{
		font-size: 18px;
	}

	@media(max-width: 608px){
		img{
			height: 100%;
		    overflow: hidden;
		    width: auto;
		}
	}

	@media(min-width: 609px){
		img{
		    width: 100%;
		}
	}

    background-color: $bleu;

	.carousel-caption{
		.border-bottom{
			@extend .pb-4;
			border-bottom-width: 3px !important;
		}
	

		top: 50%;
		transform: translateY(-50%);
		bottom: initial;
    	right: auto;
    	bottom: auto;
    	left: auto;
    	width: 100%;
	}

	@media(max-width: 1100px){
		.carousel-caption .title{
	    	margin-right: 25px;
    		margin-left: 25px;
    	}
	}
}

h1{
	span{
		border-bottom: 3px solid $bleu;
		line-height: 1.5;
	}
	color: $bleu;
	@extend .font-bold;
}

h2{
	border-bottom: 3px solid $bleu;
	color: $bleu;
	@extend .font-bold;
}

.text-red{
	color: $rouge;
}

#pictos-home{
	background: url("/images/fonds/fond_pictos.jpg");
	background-size: contain;
	padding-top: 20px;
	padding-bottom: 20px;
	background-repeat: repeat-x;

	p{
		text-align: center;
		color: $bleu;
		line-height: 1.3;
		font-weight: 700;
	}

	@media(max-width: 500px){
		.col{
			flex-grow: 0;
		    max-width: 50%;
		    width: 50%;
		    flex-basis: initial;
		}
	}
	@media(min-width: 501px) and (max-width: 813px){
		.col{
			flex-grow: 0;
		    max-width: 33%;
		    width: 33%;
		    flex-basis: initial;
		}
	}

	@media(min-width: 814px) and (max-width: 991px){
		.col{
			flex-grow: 1;
    		max-width: 14%;
		}
	}
}

footer{
	background-color: $bleu;
	color: #fff;
	@extend .pt-5;

	h4, .h5{
		@extend .font-bold;
	}

	.c1, .c2{
		border-right: 1px solid #fff;
	}

	.c1, .c2, .c3{
		@extend .pb-5;
	}

	a{
		@extend .text-white;
	}

	.row:first-of-type{

	}
	.row:nth-of-type(2){
		@extend .pt-1, .pb-2;
	}
}

.btn-red{
	@extend .btn;
	background-color: #e10a0a;
    border-radius: 25px;
    color: #fff;
    font-size: 19px !important;
    padding: 10px 20px !important;
}

.btn-big-red{
	@extend .btn;
	background-color: #951a21;
	color: #fff;
 	border-radius: 10px 10px 10px 0px;
 	font-size: 50px;
 	padding-right: 25px;
 	padding-top: 25px;
 	line-height: 1;
 	img{
 		max-width: 60px;
 	}
  	font-family: 'Bebas Neue'; 	
}

.btn-lightblue{
	@extend .btn;
	background-color: #0087fa;
    border-radius: 25px;
    color: #fff;
 font-size: 19px !important;
    padding: 10px 20px !important;
}

#newsletter_inscrit{
	input{
		border-radius: 6px;
	}
}

#somme-nous{
    background: url(/images/fonds/deco_bg_blanc.png);
    background-size: 1300px;
    background-color: $bleu;
    background-repeat: repeat-x;
    @extend .text-white;

    h2{
    	border-bottom: none;
    	span{
    		@extend .text-white;    	
			border-bottom: 2px solid #fff;
			padding-bottom: 8px;
		}
    }
}

#rejoindre-communaute{
	background-color: #f9a09f;
	color:  $bleu;
	p{
		font-size: 1.1rem;
	}
	.h1{
		span{
			border-bottom-color: $bleu !important;
			line-height: 1.5;
		}
	}
	.bg{
	    background: url(/images/fonds/rejoindre_communaute.jpg) top right #f9a09f;
	    background-size: auto 445px;
	    background-repeat: no-repeat;
	    @extend .pt-5, .pb-5;

	    @media(max-width: 990px){
    		background-position: top;

    		.col-lg-4{
    			background-color: #faebd7ad;
    			padding: 10px;
    		}
    	}
    }

    a{
    	@extend .text-white;
    }
}

.font-pld{
	font-family: 'Playfair Display', serif;
}

#carte-home{
	img{
		max-height: 180px;
		width:auto;
	}
	@media(max-width: 1199px){
		.col-img{
			margin: auto !important;
		}
	}
	@media(min-width: 1200px){
		.col-img{
			margin-left: auto !important;
		}
	}
}

#home-temoignages{
	@extend .pt-5, .pb-5, .text-white;
	background: url(/images/fonds/bg_temoignages.jpg) top left #fff;
	background-size: cover;
	p{
		font-size: 1.1rem;
	}
	
	.h1{
		@extend .text-white;
		border-bottom-color: #fff !important;
		line-height: 1.5;
	}
	.btn-red{
		display: inline-block;
		img{
			width: 30px;
		}
	}
	.temoignages{
		.temoignage{
			@media(min-width: 1160px) and (max-width: 1650px){
				.row:nth-of-type(2){
					background-color: #ffffff78;
				}
			}

			.titre{
			    background: url(/images/fonds/fond_temoignage.png);
			    background-repeat: no-repeat;

				@extend .text-white;
				padding-left: 100px;

				p:first-of-type{
					@extend .font-weight-bold,.mb-0, .pb-0;;
					font-family: 'D-DIN';
					font-size: 45px;

				}
				p:nth-of-type(2){
					font-size: 25px;
				}
			}
			.content{
				color: $bleu;
				@extend .font-pld, .position-relative;

				p{
				    margin-right: 90px;
    				margin-left: 90px;
				}
				@media(max-width:1159px){
					color: #fff;
				}

			}
			.content p:before{
				content: " ";
			    background: url(/images/pictos/open.png);
			    height: 80px;
			    width: 80px;
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: absolute;
			    left: 0px;
			    top: -26px;
			}
			.content p:after{
				content: " ";
			    background: url(/images/pictos/close.png);
			    height: 80px;
			    width: 80px;
			    background-size: 100%;
			    background-repeat: no-repeat;
			    position: absolute;
			    right: 0px;
			    bottom: -43px;
			}

			@media (max-width: 992px){
				.col-content{
					margin: auto;
				}
			}

			@media (min-width: 992px){
				.col-content{
					margin-left: auto !important;				
				}
			}
		}
	}
}

.empreinte-over{
	    background: url(/images/fonds/deco_bg_blanc_2.png);
    background-size: 1300px;
    background-repeat: repeat-x;
    width: 100%;
    content: ' ';
    position: absolute;
    height: 130px;
    bottom: 0px;
    background-position: top;
}

.replay{
	@extend .text-red, .font-weight-bold, .font-bebas;
	font-size: 18px;
}

.b-video{
	background-color: $red;
	@extend .position-relative;
 	border-radius: 10px 10px 10px 0px;
	padding-top: 60px !important;
	margin-top: 10px;

	p:first-of-type{
		background-color: #8f0e0e;
		@extend .text-white, .text-center, .font-d-din, .h2, .mb-0;
		border-top: 1px solid #fff;
		border-bottom: 1px solid #fff;
    	padding-top: 15px;
    	padding-bottom: 7px;
	}
	
	p:nth-of-type(2){
		@extend .text-white, .text-center, .p-3;	   
		margin-top: 25px;
    	padding-bottom: 25px !important;
    	line-height: 1.2;	
	}

	img{
		@extend .position-absolute, .ml-auto, .mr-auto, .text-center;
		width: 70px;
		left: 0;
		right: 0;
		top: -22px;
	}

	span{
		@extend .font-weight-bold;
	}
}

#actualite-evenements{
	
	@extend .mt-5, .mb-5;

	.t-evenements{
		color: $bleu !important;
	}

	.subtitle{
		color: $bleu;
		font-size: 20px;	
		line-height: 1;	
	}

	.actualites{
		color: $bleu;
		@extend .mb-4;

		.actualite{
			@extend .mb-4;
			p{
				@extend .mb-2;
			}

			.h2{
				border-bottom: 2px solid $bleu;
			}

			p:first-of-type{
				font-size: 18px;
	    		font-weight: bold;
			}
			
			p:nth-of-type(3){
				font-size: 20px;
			}

			p:nth-of-type(4){
				line-height: 1.1;
				a{
					@extend .font-italic, .font-weight-bold;
				}
			}

			a{
				@extend .text-red;
			}
		}
	}

	.evenements{
		.social img{
			width: 25px;
		}

		color: $bleu;

		h2, .h2{
			font-size: 2.6rem;
			border-bottom: none;
			margin-bottom: 0px;
		}

		p:first-of-type{
			font-size: 20px;
		}
	}
}

a:hover{
	text-decoration: none;
}


.fading{
    animation : 2s ease-out blur;
}
@keyframes blur{
5%{
-webkit-filter: blur(5px); /* Safari */
opacity :0;
}
}


.video-responsive {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 30px;
    position: relative;
    }
.video-responsive iframe, .video-responsive object, .video-responsive embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    }


.btn-category{
	background-color: #84bdb6;
	border-radius:10px;
	color: $bleu !important;
	text-transform: uppercase;

	a{
		color: $bleu !important;
	}
}


.btn-retour{
	background-color: #84bdb6;
	border-radius:20px;
	color: #fff !important;
	text-transform: uppercase;
	line-height: 1;
    padding: 5px 27px;
}



#search{
	input{
	  	border-radius: 15px;
	  	border-color: #cecece !important;
	  	background-color: transparent;
	 	padding-right: 32px;
   		padding-left: 46px;
	}

	input:focus, textarea:focus
	{
	    border: 1px solid #dadada;
	    box-shadow: 0 0 3px #dadada;
	    outline-offset: 0px;
	    outline: none;
	}

	input:focus,
	select:focus,
	textarea:focus,
	button:focus {
	    outline: none;
	}

	.fa-search{
	  	color: #dadada;
	  	font-size: 24px;
		padding-top: 7px;
		left: 7px;
    	position: absolute;
	}

	.fa-ellipsis-v{
		color: #dadada;
	    font-size: 24px;
	    right: 40px;
	    position: absolute;
	    top: 8px;
	}

	.fa-search:before{
		border-right: 1px solid #dadada;
    	padding-right: 10px;
	}

	::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	  color: #dadada;
	  opacity: 1; /* Firefox */
	}

	:-ms-input-placeholder { /* Internet Explorer 10-11 */
	  color: #dadada;
	}

	::-ms-input-placeholder { /* Microsoft Edge */
	  color: #dadada;
	}

}

.highlights {
	
	.first:first-child{
		height: 500px;
	}

	@media(max-width: 767px){
		.first:first-child{
			height: 246px;
		}
	}
	.highlight{
		width: auto;
		background-size: cover !important;
    	background-repeat: no-repeat !important;
    	background-position: center;	
		background-color: #fff;
		height: 246px;	
		@extend .p-4, .mt-2;

		.h2{
			max-width: 350px;
			@extend .text-white;
			text-shadow: 1px 1px #dadada;
		}
	}
}

#cat-experts{
    background: url(/images/fonds/deco_bg_blanc_2.png);
    background-size: 1300px;
    background-color: $bleu;
    background-repeat: repeat-x;
    background-position: bottom;
    @extend .text-white, .pt-5, .pb-5;

    h2{
    	border-bottom: none;
    	@extend .h1, .text-center;

    	span{
    		@extend .text-white;    	
			padding-bottom: 8px;
			border-bottom: 2px solid #fff;	
			line-height: 1.5;		
		}
    }
}
.articles{
	@extend .pt-4, .pb-4;
}

h2{
	border-bottom: none;
	@extend .h1, .text-center;
	span{
		color: $bleu;
		padding-bottom: 8px;
		border-bottom: 2px solid $bleu;		
		line-height: 1.5;	
	}
}

.articles, #cat-experts{
      .img{
    	min-height: 280px;
	    width: auto;
	    background-size: cover !important;
	    background-position: center !important;
		display: block;
    }
}

.articles, #cat-experts{
     .img{
    	background-color: #fff;
    }
}

.articles{
    .img{
    	background-color: lightgrey;
    }
}

.blog-newsletter{
    min-height: 280px;
   	width: auto; //300
	background-color: #79b6af;
	display: block;
}

.newsletter-mini{
	.mailjet{
		max-width: 90px;
		height: auto;
	}

	input{
		border-radius: 5px;
	}

	.btn-lightblue {
    	font-size: 18px;
    	@extend .font-weight-bold;
    }
}

.rejoindre{
	background-color: #f9a09f;
	color:  $bleu;
	min-height: 280px;
	width: auto; //300
	display: block;

	.h4{
		span{
			border-bottom: 1px solid $bleu;
		}
	}

	.btn-red{
		font-size: 18px;
    	@extend .font-weight-bold;
	}
}

#auteur{
	border-top: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
}

.b-out{
	background: url('/images/fonds/empreinte_bleu.jpg');	
	content: ' ';
    height: 240px;
    width: 240px;
    margin: auto;
    background-size: cover;
    background-position: center;
	border-radius: 50%;
}

.b-in{
		content: ' ';
	margin: auto;
	top: 20px;
	position: relative;
    height: 200px;
    width: 200px;
    background-size: cover !important;;
    background-position: center !important;;
    border-radius: 50%;
}


.dark-blue{
	color: #061e2f;
}
/*
::-webkit-scrollbar {
  width: 20px;
}
 
::-webkit-scrollbar-track {
  border: 4px solid #f67876;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: url('/images/scroll/test.png');
	background-repeat-x: no-repeat;
	background-position: center;
}*/

/*
#page-carte{
    background: url(/images/fonds/bg_map.jpg);
    background-repeat: no-repeat;
    background-position: right;
	background-position-x: initial;
    background-size: cover;

    @media (min-width:730px){
    	background-size: 50% 100% !important;
		background-position-x: right;
	}

	.breadcrumb{
		background: none;	
		padding: 0;
		color: dark-grey;
	}

	.themes{
		@extend .list-unstyled;
		.theme{
			@extend .text-uppercase, .text-white, .pb-1, .pt-1, .pr-2, .pl-2, .mb-2, .font-weight-bold, .w-100;
			font-size: 19px;
			max-height: 350px;
		    overflow-y: auto;
		}

		.theme span{
			@extend .pb-1, .pt-1, .pr-2, .pl-2;
			display: block;
		}
		.theme:first-of-type{ 
			::-webkit-scrollbar-thumb {
				background: url('/images/scroll/f67876.png') !important;
			}
		}

		.theme:nth-of-type(2){
			::-webkit-scrollbar-thumb {
				background: url('/images/scroll/66b7af.png') !important;
			}
		}


		.theme:first-of-type span{
			background-color: #f67876;
		}

		.theme:nth-of-type(2) span{
			background-color: #66b7af;
		}

		.theme:nth-of-type(3) span{
			background-color: #f2b557;
		}

		.theme:nth-of-type(4) span{
			background-color: #a8aaad;
		}

		.n1{
			@extend .font-weight-bold, .text-bleu, .ml-3;
			background-color: none;
			padding-top: 10px;
			
			font-size: 16px;
			span{
				@extend .p-1;
				text-decoration: underline;
				background-color: transparent !important;
				text-transform: initial;
			}
			
		}

		.n2{
			span{
				font-weight: 500;
				@extend .ml-3;
				text-decoration: none !important;
				text-transform: initial;
			}
		}

		.n3{
			@extend .ml-3;
		}

		.cnt{
			font-size: 16px;
			@extend  .text-bleu, .ml-3;
		}
	}

	h2{
		@extend .font-d-din;
		font-style: oblique; 
		text-decoration: underline;
		font-size: 30px;
	}
}
*/

#page-carte{
    background: url(/images/fonds/bg_map_3.jpg);
    background-repeat: no-repeat;
    background-position: right;
	background-position-x: initial;
    background-size: cover;

    @media (min-width:730px){
    	background-size: 50% 100% !important;
		background-position-x: right;
	}


	.themes{
		.theme{
			@extend .font-bebas;
			display: inline;
			white-space: nowrap;
			font-size: 19px;

				
		}
		p{
			font-weight: 300;
		}
		.theme.disabled{
			-webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    		filter: grayscale(100%);
    		color: grey;
    		font-weight: 500;
		}		
	}

	.active{
		font-weight: 800 !important;
		font-family: D-DIN-BOLD;
	}

	.active-last{
		color: #0b88b4;
	}

	.elements{
		max-height: 450px;
	    overflow-y: auto;

	    p{
	    	margin-bottom: 1.4rem;
	    }
	}
	a{
		display: block;
	}
	a:hover{
		color: $red !important;
	}
}


.picto-theme{
    width: auto;
    height: 30px;
    border-radius: 50%;
    @extend .mr-2;
}

.fiche-contact{
	@extend .text-bleu, .text-center;
	.b1{
		p:nth-of-type(1){
			@extend .font-bebas, .font-weight-bold, .text-uppercase, .mb-1;
			font-size: 28px;
		}
		p:nth-of-type(2){
			@extend .font-d-din, .mb-1;
			font-size: 17px;
		}
		p:nth-of-type(3){
			@extend .font-d-din, .mb-1;
			font-size: 15px;
		}
		p:nth-of-type(4){
			@extend .font-d-din, .mb-1;
			font-size: 12px;
		}
		position: relative;
		padding-bottom: 40px;
	}
	.picto{
		position: absolute;
    	bottom: -40px;
	    width: 100%;
	    margin: auto;
	   /* div{
	    	background-color: #fff;
	    	height: 90px;
	    	width: 90px;
	    	border-radius: 50%;
	    	margin: auto;
    		position: relative;
	    }*/
	    img{
	    	max-height: 80px;
	    	width: auto;
	    	/*border-radius: 50%;*/
	    	margin-top: 4px;
	    }
	}

	.b2{
		padding-top: 45px;
    	margin-top: 5px;

    	p:nth-of-type(1){
			@extend .font-bebas, .font-weight-bold, .text-uppercase;
			font-size: 24px;
		}

		p{
			@extend .mb-0, .pb-2;
		}
	}

	.b3, .b4{
	   margin-top: 5px;
	   @extend .pt-3;

		p{
	   		@extend .pb-2, .mb-0;
	   	}
	   	img{
	   		width: 40px;
	   		height: 40px;
	   	}

	}

	.b4{
		p{
	   		@extend .pb-2, .mb-0;
	   	}
	   	img{
	   		width: 40px;
	   	}

	   	.pictos{
	   		p{
	   			@extend .p-2;
	   			img{
	   				@extend .mr-2;
	   			}

	   			img{
	   			/*background-color: #fff;
			    border-radius: 50%;
			    padding: 0;*/
	   			}
	   		}
	   	}

	}
}

.elements {
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.elements::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.elements {
	align-items: flex-start;
}

.scroll-picto {
position: -webkit-sticky;
    position: sticky;
    bottom: 50%;
    /* align-self: flex-end; */
    right: 0;
    /* text-align: right; */
    margin: 0;
    float: right;
    width: 60px;

    img{
    	width: 60px;
    }
}

 #blog{
 	.highlights{
	 	.btn-category{
	 		position: absolute;
	 		bottom: 15px;
	 	}
	}
 }


 .btn-bleu-clair{
	@extend .btn;
	background-color: #66b7af;
    color: #fff !important;
    border-radius: 26px;
    font-size: 20px;
    line-height: 1;
    position: relative;
    padding-right: 75px;
    padding-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    
    img{
    	max-width: 50px;
    	position: absolute;
    	top: 0px;
    	right: 0;
    }
}

 .btn-rose{
	@extend .btn;
	background-color: #F67876;
    color: #fff !important;
    border-radius: 26px;
    font-size: 20px;
    line-height: 1;
    position: relative;
    padding-right: 75px;
    padding-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    
    img{
    	max-width: 50px;
    	position: absolute;
    	top: 0px;
    	right: 0;
    }
}


 .btn-rouge-2{
	@extend .btn;
	background-color: $rouge;
    color: #fff !important;
    border-radius: 26px;
    font-size: 20px;
    line-height: 1;
    position: relative;
    padding-right: 75px;
    padding-top: 15px;
    padding-left: 40px;
    padding-bottom: 15px;
    
    img{
    	max-width: 50px;
    	position: absolute;
    	top: 0px;
    	right: 0;
    }
}

#platf{
	#bloc-5{
		/*background: url(/images/fonds/bg_pro.jpg) #66b7af;
	    background-position: top right;
    	padding-top: 70px !important;
    	padding-bottom: 70px !important;
    	background-repeat: no-repeat;
		background-size: 1672px;*/
		background-color: #66b7af;

		hr{
			border-color: $bleu;
		}

		div p{
			color: #fff;
		}
	}
	#bloc-1{
	    background: url(/images/fonds/bg_platf3.jpg) $bleu;
	    background-position: top right;
    	padding-top: 70px !important;
    	padding-bottom: 70px !important;
    	background-repeat: no-repeat;
		background-size: 1672px;

    	@media(max-width: 990px){
    		background-position: top;
    	}



	     h1{
	     	line-height:1;
	     }
		.empreinte-over{
			background: url(/images/fonds/deco_bg_blanc_2.png);
		    background-size: 1300px;
		    background-repeat: repeat-x;
		    width: 100%;
		    content: ' ';
		    position: absolute;
		    height: 130px;
		    bottom: 0px;
		    background-position: top;
		}
		p{
			font-size: 19px;
		}

		.btn-bleu-clair{
			max-width: 425px;
		}
	}


	#bloc-2{
		/*background: url(/images/fonds/bg-chance.jpg);*/
		/*background-color: #c8c8c8;*/
		background-color: #fff;
	    /*background-size: cover;
	    background-position: bottom;
	    padding-bottom: 300px;*/
	    color: $bleu;
	    font-family: 'D-DIN';
	    font-size: 20px;


	    h1{
	    	font-family: 'D-DIN';
    		text-transform: uppercase;
    	/*	font-size: 39px;*/
	    }
	}

	#bloc-3{
		background-color: $bleu;
		p{
				font-family: 'D-DIN';
    			font-size: 20px;
    			margin-left: 10px;
    			line-height: 1;
		}

		h1 span{
			border-bottom-color: #fff;
			line-height: 1.5;
		}

		.pictos{

			img{
				max-height: 120px;
			}
		}
		.btn-bleu-clair{
			padding-right: 40px;
		}
	}

	#bloc-4{
		background: url(/images/fonds/bg-plateforme.jpg) top left #f67977;
	    background-size: auto 445px;
	    background-repeat: no-repeat;
	    @extend .pt-5, .pb-5;
	    height: 445px;
	}
}

#assoc{
	hr{
    	border-color: #27425a;
    }

    color: $bleu;
	
	#somme-nous{
		padding-top: 200px;
		padding-bottom:30px;
	    background: url(/images/fonds/assoc3.jpg);
	    background-size: cover;
	    background-color: $bleu;
	    background-repeat: no-repeat-x;
	    @extend .text-white;

	    p:first-of-type{
	        font-size: 1.3rem;
	    }

	    h2{
	    	border-bottom: none;
	    	span{
	    		@extend .text-white;    	
				border-bottom: 2px solid #fff;
				padding-bottom: 8px;
			}
	    }
	}
}
.font-1-3{
	font-size: 1.3rem;
}

.font-1-1{
	font-size: 1.1rem;
}

#mission-valeur{
 	/*background: url(/images/fonds/bg-valeurs.jpg) top right #66b7af;*/
  	background-color: #66b7af;
    background-size: auto 100%;
    background-repeat: no-repeat;
    @extend .pt-5, .pb-5;	

    .col-lg-7{
	    div{
	    	p:nth-of-type(1){
	    		font-size: 1.1rem;
	    	}
	    	p:nth-of-type(2){
	    		font-size: 1.1rem;
	    	}
	    }
	}	

	.valeurs{
		-webkit-box-shadow: 9px 24px 75px -20px rgba(0,0,0,0.75);
		-moz-box-shadow: 9px 24px 75px -20px rgba(0,0,0,0.75);
		box-shadow: 9px 24px 75px -20px rgba(0,0,0,0.75);

		p:first-of-type{
			/*@extend .mb-0;*/
			font-size: 3rem;
		}

		.valeur{
			font-size: 2.5rem;
			line-height:0.9;
			margin-bottom: 40px;
			margin-top: 35px;
			
			span{
				@extend .d-block;
				/*font-size: 3.6rem;*/
			    -webkit-text-fill-color: #ffffff00;
			    -webkit-text-stroke-color: #ffffff;
			    -webkit-text-stroke-width: 2.00px;
			    font-family: bebas neue;
			    font-size: 7rem;
			}
		}
	}

    .plus-value{
    	p:first-of-type{
    		font-size: 2.4rem;
    	}
    	div p{
    		font-size: 1.5rem !important;
    		text-transform: uppercase;
    		@extend .font-d-din, .d-inline-block, .mb-0;
    	}
    }
}

#qui_adresse{
	p{
		color: $bleu;
		@extend .mb-0, .h3;
		text-decoration: underline;
	}

	@media(min-width:768px) and (max-width:991px){
		.b-out{
			height: 200px;
    		width: 200px;
		}

		.b-in{
			height: 160px;
    		width: 160px;
		}
	}
}

#expertise{
	background-color: #66b7af;

	h2{
		font-size: 52px;
	}
}

#soutien{
	background-color: $bleu;
	@extend .text-white;

	h2{
		@extend .text-white;	
	}

	@media(max-width: 767px){
		.im{
			padding-bottom: 20px;
			padding-right: 10px !important;
			padding-left: 10px !important;
		}
	}
	.texte.col-lg-6{
	    div{
	    	p:nth-of-type(1){
	    		/*font-size: 1.3rem;*/
	    		font-size: 1.1rem;
	    		@extend .mb-0, .font-pld, .font-weight-bold;
	    	}
	    	p:nth-of-type(2){
	    		font-size: 1.1rem;
	    	}
	    }
	}	

	.rejoindre{
		min-height: auto !important;
		.h4{
			font-size: 1.5rem;
		}
	}

	ul{
		padding-inline-start: 15px;
	}
}

.b-w{
	border-bottom: 1px solid white !important;
	@extend .mt-0;
	border-color:  #fff !important;
	border: none;
}

.membre{
	.img-membre{
		content: ' ';
		margin: auto;
	    height: 200px;
	    width: 200px;
	    background-size: cover !important;;
	    background-position: center !important;;
	    border-radius: 50%;
	}

	p:nth-of-type(2){
		@extend .font-weight-bold, .mt-3;
	}
	
	p:nth-of-type(3){
		font-style: oblique; 
	}

	p{
		@extend .mb-0
	}
}

#platf #bloc-2{
	@media(max-width: 991px){
		.text-right{
			text-align: center !important;
		}
	}
}

.page_style{
	h4{
		@extend .font-pld;
	}
}